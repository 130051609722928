<script setup lang="ts">
const { globalTextData } = storeToRefs(useLanguage())
const { company } = storeToRefs(useCompany())

const callUs = (number: string) => {
  window.location.href = `tel:${number}`
}
const mailUs = (email: string) => {
  window.location.href = `mailto:${email}`
}
</script>

<template>
  <footer
    class="flex w-full gap-1 p-1 fixed -bottom-0 py-2.5 text-white"
    :style="{
      backgroundColor: company?.additional_color,
    }"
  >
    <div class="w-2/4 flex flex-col gap-1">
      <p
        class="flex items-center justify-center gap-1 h-[21px] text-center text-[14px] font-light whitespace-nowrap"
        :style="
          company?.phone.length > 13
            ? {
                fontSize: 'clamp(5px, 3vw, 16px)',
                color:
                  company?.colorConfig.footer_above_text,
              }
            : {
                color:
                  company?.colorConfig.footer_above_text,
              }
        "
      >
        <i
          class="pi pi-phone"
          :style="{
            color: company?.colorConfig.footer_above_icon,
          }"
        ></i>
        <span>{{ company?.phone }} </span>
      </p>
      <Button
        class="w-full font-bold border-2 bg-transparent rounded-xl"
        :style="{
          borderColor:
            company?.colorConfig.footer_btn_stroke,
          color: company?.colorConfig.footer_btn_text,
        }"
        :label="globalTextData?.call_btn"
        @click="callUs(company?.phone)"
        severity="secondary"
        raised
      />
    </div>
    <div class="w-2/4 flex flex-col gap-1">
      <p
        class="flex items-center justify-center gap-1 h-[21px] text-center text-[14px] font-light whitespace-nowrap"
        :style="
          company?.email.length > 16
            ? {
                fontSize: 'clamp(2px, 2.5vw, 16px)',
                color:
                  company?.colorConfig.footer_above_text,
              }
            : {
                color:
                  company?.colorConfig.footer_above_text,
              }
        "
      >
        <i
          class="pi pi-envelope"
          :style="{
            color: company?.colorConfig.footer_above_icon,
          }"
        ></i>
        <span> {{ company?.email }}</span>
      </p>
      <Button
        class="w-full font-bold border-2 bg-transparent rounded-xl"
        :style="{
          borderColor:
            company?.colorConfig.footer_btn_stroke,
          color: company?.colorConfig.footer_btn_text,
        }"
        :label="globalTextData?.mail_btn"
        @click="mailUs(company?.email)"
        severity="secondary"
        raised
      />
    </div>
  </footer>
</template>
