<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

const isMenuVisible = ref(false)
const modalRef = ref<HTMLElement | null>(null)
const { globalTextData, languages, selectedLanguage } =
  storeToRefs(useLanguage())
const { company } = storeToRefs(useCompany())
const imageUrl = computed(() =>
  bufferToBase64(company.value.logo.data),
)
const { folder } = storeToRefs(useFolders())

useHead({
  title: company.value?.name,
  link: [
    {
      rel: 'icon',
      type: 'image/png',
      href: bufferToBase64(company.value?.favicon.data),
    },
  ],
})

const toggleVisibility = () => {
  isMenuVisible.value = !isMenuVisible.value
}

onClickOutside(modalRef, (event: PointerEvent) => {
  if (event.target!.className === 'pi pi-times') {
    return
  }
  isMenuVisible.value = false
})

const selectChange = async () => {
  await useLanguage().updateValue()
}
</script>

<template>
  <header
    class="flex w-full fixed top-0 justify-between border-b border-gray-200 items-center p-5 gap-3"
    :style="{
      backgroundColor: company?.colorConfig.header_bg,
    }"
  >
    <div
      :style="{
        backgroundColor: company?.main_color,
      }"
    >
      <img
        :src="imageUrl"
        alt="Company image"
        class="p-0.5 w-max-[140px] h-[36px] block"
      />
    </div>

    <div
      class="flex justify-center items-center gap-3 overflow-hidden"
    >
      <p
        class="font-bold whitespace-nowrap truncate flex-shrink"
        :style="{
          color: company?.colorConfig.header_text_fill,
          textShadow: `1px 1px 0px ${company?.colorConfig.header_text_stroke}`,
        }"
      >
        {{ globalTextData?.discover }}
      </p>
      <div
        class="text-2xl flex-shrink-0"
        @click="toggleVisibility"
      >
        <i
          v-if="!isMenuVisible"
          class="pi pi-bars"
          :style="{
            color: company?.colorConfig.header_icon,
          }"
        ></i>
        <i
          v-else
          class="pi pi-times"
          :style="{
            color: company?.colorConfig.header_icon,
          }"
        ></i>
      </div>
    </div>

    <div
      v-show="isMenuVisible"
      ref="modalRef"
      class="p-4 bg-white fixed top-[77px] -right-0 z-10 w-80 overflow-auto side-bar"
    >
      <RouterLink
        v-for="subpage in company.subpages.filter(
          (subpage) =>
            subpage.language.id == selectedLanguage?.id,
        )"
        :key="subpage.id"
        @click="toggleVisibility"
        class="text-lg border-b border-black font-semibold mb-3 py-4 leading-none w-full block"
        :to="{
          path: $route.path,
          query: {
            companyId: company?.id,
            pageIndex: subpage.page_index,
          },
        }"
        :style="{
          color: company?.text_color,
        }"
      >
        {{ subpage.page_name }}
      </RouterLink>

      <RouterLink
        v-if="folder.protocol"
        @click="toggleVisibility"
        class="text-lg border-b border-black font-semibold mb-3 py-4 leading-none w-full block"
        :to="{
          path: $route.path + '/protocol',
        }"
        :style="{
          color: company?.text_color,
        }"
      >
        Protocol {{ folder.protocol.title }}
      </RouterLink>

      <h2
        class="text-[24px] font-semibold pt-8 mb-2"
        :style="{
          color: company?.text_color,
        }"
      >
        {{ globalTextData?.contact }}
      </h2>
      <p
        class="p-2 border-l-2 font-medium text-[20px] mb-3 break-words whitespace-normal"
        :style="{
          borderColor:
            company?.colorConfig.footer_above_icon,
        }"
      >
        <i
          class="pi pi-phone mr-3"
          :style="{
            color: company?.colorConfig.footer_above_icon,
          }"
        ></i>
        <span
          :style="{
            color: company?.text_color,
          }"
        >
          {{ company?.phone }}
        </span>
      </p>
      <p
        class="p-2 border-l-2 font-medium text-[20px] mb-3 break-words whitespace-normal"
        :style="{
          borderColor:
            company?.colorConfig.footer_above_icon,
        }"
      >
        <i
          class="pi pi-envelope mr-3"
          :style="{
            color: company?.colorConfig.footer_above_icon,
          }"
        ></i>
        <span
          :style="{
            color: company?.text_color,
          }"
        >
          {{ company?.email }}
        </span>
      </p>
      <div
        class="p-2 border-l-2 font-medium text-[20px] mb-3 break-words whitespace-normal"
        :style="{
          borderColor:
            company?.colorConfig.footer_above_icon,
        }"
      >
        <i
          class="pi pi-map mr-3 pt-2"
          :style="{
            color: company?.colorConfig.footer_above_icon,
          }"
        ></i>
        <p
          :style="{
            color: company?.text_color,
          }"
        >
          {{ company?.address }}
        </p>
      </div>

      <h2
        class="text-[20px] font-medium mt-4 mb-1"
        :style="{
          color: company?.text_color,
        }"
      >
        {{ globalTextData?.local }}
      </h2>
      <Select
        v-model="selectedLanguage"
        :options="languages"
        optionLabel="name"
        placeholder="Select a language"
        class="w-full p-2.5 border-gray-300 text-sm mb-20"
        @change="selectChange"
      />
      <div
        class="fixed bottom-0 right-0 px-1 bg-gray-500/50 text-white text-[12px]"
      >
        v{{ useRuntimeConfig().public.version }}
      </div>
    </div>
  </header>
</template>

<style scoped>
.side-bar {
  box-shadow: -6px 4px 10.5px #00000078;
  height: calc(100dvh - 67px);
}
</style>
